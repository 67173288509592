import React from "react";
import { Container } from "react-bootstrap";
import MainSection from "../components/homepage/main";

const HomePage = () => {


    return (<Container fluid>
        <MainSection />
    </Container >
    )
}
export default HomePage;